import "../../client/css/index.css";
import { domContentLoaded, renderComponents } from "@btu-tools/master-builder-client";
import { initRemoteComponentContext, IRemoteComponentContext } from "@bookingcom/remote-component";
import { LandingPageRows } from "../../components/LandingPageRows";
import { TaxiBookPageOrchestrator } from "../../orchestrator/TaxiBookPageOrchestrator";
import { SearchFormHandler } from "../../components/SearchFormHandler";
import { SearchParamsHandler } from "../../components/SearchParamsHandler";
import { HeroBanner } from "../../components/HeroBanner";
import { AirportTaxiCarousel } from "../../components/AirportTaxisCarousel";
import { Faq } from "../../components/Faq";
import { GeniusBanner } from "../../components/GeniusBanner";
import { registerLingoTags } from "../../constants/registerLingoTags";

let remoteComponentContext: IRemoteComponentContext;

// register all translation tags used in the mfe
registerLingoTags();

const populateRemoteComponentContext = async (): Promise<void> => {
	await domContentLoaded();
	const contextKeys = Object.keys(window.globalContext?.orcaClientOptions?.translationRequestContext);

	if (contextKeys.length === 0) {
		const emptyContext = {
			emptyContext: true,
		};

		window.globalContext.orcaClientOptions.translationRequestContext = emptyContext;
	}

	remoteComponentContext = await initRemoteComponentContext(
		window.fetch.bind(window),
		window.globalContext.orcaClientOptions,
		{
			// eslint-disable-next-line no-console
			logError: (error) => console.error("Error with remote component", error),
			// eslint-disable-next-line no-console
			logWarning: (error) => console.warn("Warning with remote component", error),
		},
	);
};

window.orchestrator = new TaxiBookPageOrchestrator();

// eslint-disable-next-line unicorn/prefer-top-level-await
(async () => {
	await populateRemoteComponentContext();

	renderComponents({
		AirportTaxiCarousel,
		Faq,
		GeniusBanner: {
			component: GeniusBanner,
			props: { remoteComponentContext },
		},
		HeroBanner,
		LandingPageRows,
		SearchFormHandler,
		SearchParamsHandler,
	});
})();

/* istanbul ignore next */
if (module.hot) {
	module.hot.accept("../../components/RouteSummaryWrapper", async () => {
		await populateRemoteComponentContext();

		/* eslint-disable @typescript-eslint/no-require-imports */
		renderComponents({
			AirportTaxiCarousel: require("../../components/AirportTaxisCarousel").AirportTaxiCarousel,
			AirportTaxis: require("../../components/LandingPageRows").AirportTaxis,
			AirportTransfers: require("../../components/LandingPageRows").AirportTransfers,
			Faq: require("../../components/Faq").Faq,
			GeniusBanner: {
				component: require("../../components/GeniusBanner").GeniusBanner,
				props: { remoteComponentContext },
			},
			HeroBanner: require("../../components/HeroBanner").HeroBanner,
			HowItWorks: require("../../components/LandingPageRows").HowItWorks,
			LandingPageRows: require("../../components/LandingPageRows").LandingPageRows,
		});
		/* eslint-enable @typescript-eslint/no-require-imports */
	});
}
