import axios from "axios";
import { removeTrailingSlash } from "../utils/remove-trailing-slash";
import { BroadcastableRateResult } from "../types/taxi-search-results";
import { SearchResultsParams } from "../utils/mfe-url-generator/types";
import { DEFAULT_CURRENCY } from "../constants/defaults";
import { GetReturnRateParameters } from "./TaxiBookPageClient.types";

export {
	trackExperiment,
	trackExperimentGoal,
	trackExperimentStage,
} from "@btransport/rides-et-middleware/client-side";

export const encryptComments = async (dataToEncrypt: string): Promise<string | undefined> => {
	try {
		const pathName = removeTrailingSlash(`${window.location.pathname}`);
		const { data } = await axios.post<string>(`${pathName}/encrypt`, {
			dataToEncrypt,
		});

		return data;
	} catch {
		return undefined;
	}
};

export const getReturnRate = async (
	baseUrl: string,
	parameters: GetReturnRateParameters,
): Promise<BroadcastableRateResult> => {
	if (!parameters.returnDate || +parameters.pickupDate < Date.now()) {
		return;
	}

	const returnSearchParams: SearchResultsParams = {
		affiliate: parameters.affiliate,
		currency: parameters.currency || DEFAULT_CURRENCY,
		displayLocalSupplierText: "true",
		dropoff: parameters.dropoff,
		dropoffEstablishment: parameters.dropoffEstablishment,
		dropoffType: parameters.dropoffType,
		format: "envelope",
		isExpandable: "true",
		language: parameters.language,
		passenger: `${parameters.passengers}`,
		pickup: parameters.pickup,
		pickupDateTime: parameters.pickupDate.toISOString(),
		pickupEstablishment: parameters.pickupEstablishment,
		pickupType: parameters.pickupType,
		populateSupplierName: "true",
		returnDateTime: parameters.returnDate.toISOString(),
		returnJourney: "true",
	};

	const SEARCH_RESULTS_URL = `${baseUrl}/rates?${new URLSearchParams(returnSearchParams as Record<string, string>).toString()}`;

	try {
		const response = await fetch(SEARCH_RESULTS_URL, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			throw new Error(`Error fetching return rate data: ${response.statusText}`);
		}

		const rateResult = await response.json();

		return rateResult;
	} catch (error) {
		console.error({
			logTag: "ADD_RETURN",
			message: "Client side request to Rates failed",
			error,
		});

		throw error;
	}
};
